import React from "react"
import NavbarItemPanels from "./NavbarItemPanels"
import "../styles/components/SecondaryNavbar.styl"

export default function SecondaryNavbarPanels({ sections, handleOnClick }) {
  return (
    <nav className="SNavbar">
      {sections !== undefined && (
        <ul className="nav-list">
          {sections.map(params => (
            <NavbarItemPanels
              key={params.type}
              handleOnClick={handleOnClick}
              rightToSelected={false}
              leftToSelected={true}
              type={params.type}
              type__className={params.className}
            />
          ))}
        </ul>
      )}
    </nav>
  )
}
