import React from "react"
import "../styles/components/PanelsTitle.styl"

export default function PanelsTitle({ subtitle }) {
  return (
    <div className="PanelsTitle">
      <p className="panels__title">Quienes ya se encuentran ahorrando</p>
      <h2 className="panels__subtitle">{subtitle}</h2>
    </div>
  )
}
