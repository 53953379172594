import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import "../styles/components/ClientInstance.styl"
export default function ClientInstance({
  user,
  tecnology,
  potency,
  date,
  location,
  image,
  direction,
  lastOne,
}) {
  //Bussiness
  const theItalianCoffe = "../images/solarPanelBusinessClients/italianCoffe.jpg"
  const laserpil = "../images/solarPanelBusinessClients/laserpil.jpg"
  const pelayo = "../images/solarPanelBusinessClients/pelayo.jpg"
  const elTriunfo = "../images/solarPanelBusinessClients/triunfo.jpg"
  const araiza = "../images/solarPanelBusinessClients/araiza.jpg"
  const elMuñeco = "../images/solarPanelBusinessClients/elMuñeco.jpg"
  //Residential
  const house1 = "../images/solarPanelResidentialClients/casaHabitacion-1.jpg"
  const house2 = "../images/solarPanelResidentialClients/casaHabitacion-2.jpg"
  const house3 = "../images/solarPanelResidentialClients/casaHabitacion-3.jpg"

  return (
    <div className={`ClientInstance ` + direction}>
      <div className="clientInstance__img">
        {image === "theItalianCoffe" && (
          <StaticImage src={theItalianCoffe} objectFit={"cover"} />
        )}
        {image === "laserpil" && (
          <StaticImage src={laserpil} objectFit={"cover"} />
        )}
        {image === "pelayo" && <StaticImage src={pelayo} objectFit={"cover"} />}
        {image === "elTriunfo" && (
          <StaticImage src={elTriunfo} objectFit={"cover"} />
        )}
        {image === "araiza" && <StaticImage src={araiza} objectFit={"cover"} />}
        {image === "elMuñeco" && (
          <StaticImage src={elMuñeco} objectFit={"cover"} />
        )}
        {image === "house1" && <StaticImage src={house1} objectFit={"cover"} />}
        {image === "house2" && <StaticImage src={house2} objectFit={"cover"} />}
        {image === "house3" && <StaticImage src={house3} objectFit={"cover"} />}
      </div>
      <div className="clientInstance__desc">
        <ul className="c-desc-list">
          <li className="c-desc-list__text">- Usuario: {user}</li>
          <li className="c-desc-list__text">- Tecnología: {tecnology}</li>
          <li className="c-desc-list__text">
            - Potencia del Sistema: {potency}
          </li>
          <li className="c-desc-list__text">
            - Fecha de Implementación: {date}
          </li>
          <li className="c-desc-list__text">- Ubicación: {location}</li>
        </ul>
      </div>
      <div className={"clientInstance-bottom-bar"}>
        {lastOne !== true && (
          <div className="clientInstance-bottom-bar__blue-space" />
        )}
      </div>
    </div>
  )
}
