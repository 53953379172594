import * as React from "react"
import { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SecondaryNavbarPanels from "../components/SecondaryNavbarPanels"
import PanelsTitle from "../components/PanelsTitle"
import ClientInstance from "../components/ClientInstance"
import { MyProvider } from "../components/context"
import CallToActionButton from "../components/CallToActionButton"

export default function PanelesSolares() {
  const [typeOfPanelClients, setTypeOfPanelClients] = useState("Negocios")

  function handleOnClick(typeOfClients) {
    if (typeOfClients === "Residencial") {
      setTypeOfPanelClients("Residencial")
    } else setTypeOfPanelClients("Negocios")
  }

  return (
    <MyProvider>
      <Layout>
        <Seo title="Paneles solares" />
        <SecondaryNavbarPanels
          sections={[
            { type: "Negocios", className: "business" },
            { type: "Residencial", className: "residential" },
          ]}
          sectionName="panels"
          handleOnClick={handleOnClick}
        />
        <PanelsTitle subtitle={typeOfPanelClients} id="Panels" />
        {typeOfPanelClients === "Negocios" && (
          <>
            <ClientInstance
              user="The Italian Coffe - Tequis"
              tecnology="Fotovoltaico"
              potency="11.04 Kw"
              date="Noviembre 2018"
              location="Mariano Arista"
              image={"theItalianCoffe"}
              direction=""
            />
            <ClientInstance
              user="Laserpil"
              tecnology="Fotovoltaico"
              potency="8.28 Kw"
              date="Julio 2018"
              location="Cordillera Himalaya"
              image={"laserpil"}
              direction="ClientInstance--right"
            />
            <ClientInstance
              user="Llantera Pelayo - Lomas"
              tecnology="Fotovoltaico"
              potency="15.18 Kw"
              date="Enero 2019"
              location="Av. Salvador Nava Martínez"
              image={"pelayo"}
              direction=""
            />
            <ClientInstance
              user="Cremería Araiza"
              tecnology="Fotovoltaico"
              potency="17.82 Kw"
              date="Mayo 2020"
              location="Av. Hernán Cortes"
              image={"araiza"}
              direction="ClientInstance--right"
            />
            <ClientInstance
              user={`Llantera Abarrotes "El Muñeco"`}
              tecnology="Fotovoltaico"
              potency="17.28 Kw"
              date="Mayo 2021"
              location="C. Valle de la Aurora"
              image={"elMuñeco"}
              direction=""
              lastOne={true}
            />
          </>
        )}
        {typeOfPanelClients === "Residencial" && (
          <React.Fragment>
            <ClientInstance
              user={`Casa Habitación`}
              tecnology="Fotovoltaico"
              potency="3.4 Kw"
              date="Noviembre 2018"
              location="C. Pase del Rio, Campestre de Golf"
              image={"house1"}
              direction="ClientInstance--right"
              lastOne={false}
            />
            <ClientInstance
              user={`Casa de Descanso`}
              tecnology="Fotovoltaico"
              potency="4.32 Kw"
              date="Mayo 2019"
              location="Camino a las Arboledas, Sta. Ma. del Rio"
              image={"house2"}
              direction=""
              lastOne={false}
            />{" "}
            <ClientInstance
              user={`Casa Habitación`}
              tecnology="Fotovoltaico"
              potency="1.64 Kw"
              date="Junio 2021"
              location="Aquiles, Villa Magna"
              image={"house3"}
              direction="ClientInstance--right"
              lastOne={true}
            />
          </React.Fragment>
        )}
        <CallToActionButton />
      </Layout>
    </MyProvider>
  )
}
